<template>
	<div class="home">
        <div id="hero">
			<img src="@/assets/images/EME_logo.svg">
			<p class="title wide">Creating Smart Musicians</p>
		</div>
		
		<div id="content" class="home-content blue-box">
            <div class="content-wide">
                <p class="heading">Edit Teacher Listings</p>
                
                <button v-if="isEditing" class="button-round inverted" @click="clearEditing">Back</button>
                <button v-else class="button-round inverted" @click="createTeacher">New Teacher</button>

                <button class="button-round inverted" @click="logout">Logout</button>
            </div>
		</div>

        <div class="teacher-edit-container" v-show="isEditing">
            <div class="teacher-edit-account" v-if="this.editingTeacherId">
                <p class="info-bold">Account Information</p>

                <button @click="createAccount" class="button-round" v-if="!this.newTeacher.hasAccount" :disabled="!this.newTeacher.email">Setup Account</button>
                <div v-else>
                    <p class="info">Resource Access</p>
                   <div class="controls subcollection">
						<multiselect
							v-model="newTeacher.accessGroups"
							:options="accessGroupIds"
							:multiple="true"
							:close-on-select="false"
							:clear-on-select="false"
							:preserve-search="true"
							placeholder="Select access groups"
							label="name"
							track-by="id">
						</multiselect>
					</div>
                </div>
            </div>

            <form @submit.prevent>
                <div class="disabled-container">
                    <input type="checkbox" v-model="newTeacher.inactive" id="active"><label for="active">Mark inactive (remove from listing)</label>
                </div>
                <input type="text" v-model.trim="newTeacher.name" placeholder="Business Name">
                <input type="text" v-model.trim="newTeacher.teacherName" placeholder="Teacher name">
                <input placeholder="Address" :value="newTeacher.address" type="text" ref="searchBox"/>
                <button class="button-round" style="margin-bottom: 50px" @click="clearAddress">Clear Address</button>
                <input type="text" v-model.trim="newTeacher.phone" placeholder="Phone">
                <input type="text" v-model.trim="newTeacher.alternatePhone" placeholder="Alternate phone">
                <input type="text" v-model.trim="newTeacher.email" placeholder="Email">
                <input type="text" v-model.trim="newTeacher.website" placeholder="Website">
                <textarea v-model.trim="newTeacher.notes" placeholder="Notes"></textarea>

                <input type="file" ref="imageInput" style="display: none" @change="previewImage" accept="image/*">

                <div class="select-container">
                    <label for="type" class="for-select">Teacher Type</label>
                    <select name="type" id="type" v-model="newTeacher.type">
                        <option :value="0" selected>N/A</option>
                        <option :value="1">Licenced Teacher</option>
                        <option :value="2">Music Academy</option>
                    </select>
                </div>

                <div class="courses-select-container">
                    <div>
                        <input type="checkbox" id="kb" v-model="newTeacher.kb"><label for="kb">Music Box Magic</label>
                    </div>

                    <div>
                        <input type="checkbox" id="pp" v-model="newTeacher.pp"><label for="pp">Percussion Play</label>
                    </div>

                    <div>
                        <input type="checkbox" id="kbp" v-model="newTeacher.kbp"><label for="kbp">Kinder Beat Piano</label>
                    </div>

                    <div>
                        <input type="checkbox" id="pr" v-model="newTeacher.pr"><label for="pr">Primary Piano</label>
                    </div>

                    <div>    
                        <input type="checkbox" id="jr" v-model="newTeacher.jr"><label for="jr">Junior Piano</label>
                    </div>

                    <div>    
                        <input type="checkbox" id="acc" v-model="newTeacher.acc"><label for="acc">Accent Piano</label>
                    </div>
                    
                    <div>    
                        <input type="checkbox" id="ach" v-model="newTeacher.ach"><label for="ach">Achiever Piano</label>
                    </div>

                    <div>    
                        <input type="checkbox" id="st" v-model="newTeacher.st"><label for="st">Encore on Strings</label>
                    </div>

                    <div>    
                        <input type="checkbox" id="tw" v-model="newTeacher.tw"><label for="tw">Theory Wiz</label>
                    </div>
                </div>

                <div class="photo-upload-container">
                    <div v-if="preview" class="photo-upload-image-container">
                        <img :src="preview">
                        <button class="material-icons image-remove" @click="removeImage()">close</button>
                    </div>

                    <p v-else>There is no image currently uploaded for this teacher.</p>

                    <button @click="uploadImage" class="button-round save-teacher-button">{{ this.uploadValue != 0 ? `${this.uploadValue}%` : 'Choose a Photo' }}</button>
                </div>

                <button @click="saveNewTeacher" class="button-round save-teacher-button">Save</button>
            </form>
        </div>
        <div class="teacher-edit-container" v-show="!isEditing">
            <p class="heading">There are currently <span>{{ teacherList.length }}</span> listed teachers</p>
                
            <div class="teacher-result" v-for="(teacher, i) in teacherList" :key="i">
                <div class="teacher-result-top">
                    <p class="name">{{ teacher.name ? teacher.name : teacher.teacherName }}</p>
                    <button class="edit-button button-round" @click="removeTeacher(teacher.id)">Remove</button>
                    <button class="edit-button button-round" @click="editListing(teacher)">Edit</button>
                </div>
                
                <p class="person" v-if="teacher.name">{{ teacher.teacherName }}</p>
                <p class="address">{{ teacher.address }}</p>
                <p class="number">{{ teacher.phone }}</p>
                <p class="address">{{ teacher.email }}</p>
            </div>
        </div>
	</div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { mapActions, mapState } from 'vuex'
import { db, auth, functions } from '@/firebase'

import { storage } from '@/firebase';
import Multiselect from 'vue-multiselect'

let defaultTeacher = {
    inactive: false,
    name: '',
    teacherName: '',
    address: '',
    lat: 0,
    lng: 0,
    phone: '',
    email: '',
    website: '',
    notes: '',
    image: null,
    kb: false,
    pp: false,
    kbp: false,
    pr: false,
    jr: false,
    acc: false,
    ach: false,
    st: false,
    tw: false,
    type: 0,
    hasAccount: false,
    accessGroups: []
};

export default {
	data() {
		return {
            isEditing: false,
            editingTeacherId: null,
            newTeacher: defaultTeacher,
            uploadValue: 0,
            storageRef: null,
            preview: null,
            imageNeedsDeleting: false,
            teacherCollections: {}
		}
    },
    components: {
		Multiselect
    },
    computed: {
        ...mapState('teachers', { teacherList: 'list' }),
        ...mapState('resources', ['accessGroups']),
        accessGroupIds() {
			if (this.accessGroups) {
				return this.accessGroups.map((val) => ({ name: val.name, id: val.id }));
			} else {
				return [];
			}
		}
    },
	methods: {
		initAutocomplete() {
			this.autocomplete = new window.google.maps.places.Autocomplete(this.$refs['searchBox']);
			this.autocomplete.setFields(["formatted_address", "geometry"]);

			this.autocomplete.addListener("place_changed", () => {
                const place = this.autocomplete.getPlace();

                this.newTeacher.lat = place.geometry.location.lat();
                this.newTeacher.lng = place.geometry.location.lng();
                this.newTeacher.address = place.formatted_address;
			});
        },
        clearAddress() {
            this.newTeacher.lat = 0;
            this.newTeacher.lng = 0;
            this.newTeacher.address = "";
        },
        createAccount() {
            if (confirm("Are you sure you want to create this teacher an account?")) {
                functions.httpsCallable('createTeacherAccount')({
                    ...this.newTeacher,
                    id: this.editingTeacherId
                }).then(() => {
                    return db.collection('teachers').doc(this.editingTeacherId).update({
                        hasAccount: true
                    })
                }).then(() => {
                    this.newTeacher.hasAccount = true;
                    this.$toast.open("Account Created");
                }).catch((error) => {
                    this.$toast.open({ message: error, type: 'warn' })
                });
            }
        },
        createTeacher() {
            this.newTeacher = defaultTeacher;

            this.isEditing = true;
        },
        clearEditing() {
            this.isEditing = false;
            this.editingTeacherId = null;
            this.newTeacher = defaultTeacher;
            
            if (this.storageRef !== null) {
                this.storageRef.delete();
                this.storageRef = null;
            }
        },
        editListing(teacher) {
            this.isEditing = true;
            this.editingTeacherId = teacher.id;
            this.newTeacher = {
                ...teacher
            }
        },
        removeTeacher(id) {
            // TODO: Display the teacher name
            if (confirm(`Are you sure you want to remove this teacher?`)) {
                db.collection('teachers').doc(id).delete();
            }
        },
        saveNewTeacher() {
            if (this.editingTeacherId) {
                db.collection('teachers').doc(this.editingTeacherId).update({
                    ...this.newTeacher,
                }).then(() => {
                    if (this.imageNeedsDeleting) {
                        return this.storageRef.delete();   
                    }                    
                }).then(() => {
                    this.imageNeedsDeleting = false;
                    this.storageRef = null;
                    this.clearEditing();
                });
            } else {
                db.collection('teachers').add({
                    ...this.newTeacher
                }).then(() => {
                    if (this.imageNeedsDeleting) {
                        return this.storageRef.delete();   
                    }
                }).then(() => {
                    this.imageNeedsDeleting = false;
                    this.storageRef = null;
                    this.clearEditing();
                });
            }
        },
        uploadImage() {
            this.$refs.imageInput.click();
        },
        previewImage(event) {
            this.uploadValue = 0;
            this.newTeacher.image = null;
            this.imageData = event.target.files[0];
            this.onUpload()
        },
        removeImage() {
            this.$set(this.newTeacher, 'image', null);
            this.preview = null;
        },
        onUpload() {
            if (this.storageRef != null) {
                this.storageRef.delete();
            }

            this.newTeacher.image = null;
            this.storageRef = storage.ref(`teachers/${this.imageData.name}`);
            this.storageRef.put(this.imageData).on('state_changed', snapshot => {
                this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            }, error => {
                console.log(error.message);
            }, () => {
                this.uploadValue = 100;
                this.storageRef.getDownloadURL().then(url => {
                    this.$set(this.newTeacher, 'image', url);
                });

                setTimeout(() => {
                    this.uploadValue = 0;
                }, 1000);
            });
        },
        logout() {
            auth.signOut().then(() => {
                this.$router.push("/")
            })
        },
		...mapActions('teachers', ['bindList']),
	},
    metaInfo() {
		return {
			script: [{
				src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAXypMM2uB-mioDJ-xX5sCSEAL2fiZGgMY&libraries=places',
				async: true,
				defer: true,
				callback: () => this.initAutocomplete()
			}]
		}
	},
	mounted() {
		this.bindList();
        this.$store.dispatch("resources/setupResourceBinds")

        window.onbeforeunload = () => {
            if (this.storageRef !== null) {
                this.storageRef.delete();
            }
        }
	},
    beforeDestroy() {
        if (this.storageRef != null) {
            this.storageRef.delete();
        }
    },
    watch: {
        'newTeacher.image': function(newVal) {
            if (newVal !== null && this.storageRef == null) {
                this.storageRef = storage.refFromURL(newVal);
            }

            this.imageNeedsDeleting = newVal === null && this.storageRef !== null;
            this.preview = newVal;
        }
    }
}
</script>